import { Link } from 'gatsby'
import { navigate } from '@reach/router'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React from 'react'
import alt from '../content/alt'
import { colors, s, alpha } from '../style'

const BlogTile = ({ data, prefix }) => {
  const { title, desc, image, slug, noExcerpt } = data
  const parentSlug = prefix || '/blog/'
  const url = parentSlug + slug + '/'

  return (
    <div
      css={sContainer}
      onClick={() => navigate(url)}
      onKeyPress={() => navigate(url)}
      role="button"
      tabIndex={0}
    >
      <GatsbyImage
        image={getImage(image)}
        alt={title + alt}
        css={sImage}
        imgClassName="BlogTile-image"
      />
      <div css={sText}>
        <Link to={url} css={sTitle}>
          {title}
        </Link>
        <p
          css={[
            sDesc,
            title.length > 18 && { WebkitLineClamp: 2 },
            title.length > 40 && { WebkitLineClamp: 1 },
          ]}
        >
          {desc}
        </p>
      </div>
    </div>
  )
}

export const sContainer = {
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '1rem',
  background: `linear-gradient(to right, ${colors.brand}, ${colors.brandDarker})`,
  '.BlogTile-image': {
    transition: 'transform 0.2s ease-out',
    borderRadius: '1rem',
  },
  '&:hover': {
    [s.hover]: {
      '.BlogTile-image': {
        transform: 'scale(1.25)',
      },
    },
  },
}

export const sImage = {
  borderRadius: '1rem 1rem 0 0',
}

export const sText = {
  display: 'flex',
  flexDirection: 'column',
  padding: '1.5rem 2rem 3rem',
}

export const sTitle = {
  fontSize: '1.25rem',
  lineHeight: '1.35em',
  fontWeight: 700,
  marginBottom: '1rem',
  color: 'white',
}

export const sDesc = {
  fontWeight: 500,
  fontSize: '1rem',
  lineHeight: '1.35em',
  color: colors.brandLighter.concat(alpha[80]),
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}

export default BlogTile

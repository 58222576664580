import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import BlogTile from '../../components/BlogTile'
import Button from '../../components/Button'
import Main from '../../components/Main'
import { useLangContext } from '../../context/lang.context'
import { s } from '../../style'

const content = {
  title: {
    pl: 'Aktualności, wydarzenia,<br/> artykuły z branży TSL',
    en: 'News, events,<br/>TSL articles',
  },
  desc: {
    pl: 'Zachęcamy do przeczytania naszego bloga.',
    en: 'Are you interested in events in the TSL industry? See our articles about the latest transport and company news.',
  },
  subtitle: 'Sea & Air Blog',
  button: {
    text: {
      pl: 'Więcej artykułów',
      en: 'More articles',
    },
    link: '/blog/',
  },
}

const BlogSection = ({ skip }) => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      blogs: allContentfulBlog(sort: { fields: date, order: DESC }) {
        nodes {
          blog_slug
          lang {
            slug
          }
          title
          description
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
              aspectRatio: 1.77
            )
          }
        }
      }
    }
  `)

  const blogs = query.blogs.nodes
    .filter((node) => node.lang.slug === 'pl')
    .map(({ title, description, image, blog_slug }) => ({
      title,
      desc: description,
      slug: blog_slug,
      image,
    }))

  return (
    <Main
      h={2}
      s={1}
      title={content.title[lang]}
      desc={content.desc[lang]}
      subtitle={content.subtitle}
    >
      <div css={sBlogs}>
        {blogs
          .filter((blog, id) => (skip ? id < skip : true))
          .map((blog, id) => (
            <BlogTile key={id} data={blog} />
          ))}
      </div>
      {skip && (
        <Button link={content.button.link}>{content.button.text[lang]}</Button>
      )}
    </Main>
  )
}

export const sBlogs = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '4rem',
  margin: '4rem 0',
  [s.sm_down]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
    gap: '2rem',
    margin: '4rem 0 2rem',
  },
}

export default BlogSection

import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Banner from '../../components/Banner'
// import { useFormContext } from '../../context/form.context'

const bannerContent = [
  {
    text: {
      pl: 'Wyślij nam swoje CV',
      en: 'Send your resume',
    },
    subtitle: {
      pl: 'Zainteresowany?',
      en: 'Are you interested in?',
    },
    link: 'mailto:rekrutacja@seaandair.pl',
  },
  {
    text: {
      pl: 'rekrutacja@seaandair.pl',
      en: 'rekrutacja@seaandair.pl',
    },
    subtitle: {
      pl: 'Napisz wiadomość',
      en: 'Send a message',
    },
    link: 'mailto:rekrutacja@seaandair.pl',
  },
]

// const label = {
//   text: {
//     pl: 'Szybki kontakt',
//     en: 'Quick contact',
//   },
//   color: '#036FAE',
// }

const BannerRecruitment = () => {
  // const { setFormType, setFormOpen } = useFormContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/banners/career.jpg/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return <Banner data={bannerContent} image={image} noSection />
}

export default BannerRecruitment

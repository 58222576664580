import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import {
  seo,
  intro,
  main,
  features,
  join,
  video,
  supporting,
} from '../../content/kariera'
import { useLangContext } from '../../context/lang.context'
import Intro from '../../components/Intro'
import Main from '../../components/Main'
import ImageSection from '../../components/ImageSection'
import Features from '../../components/Features'
import JobSection from '../../sections/index/JobSection'
import { main as jobsMain } from '../../content/praca'
import Collapses from '../../components/Collapses'
import BannerRecruitment from '../../sections/banners/BannerRecruitment'
import Video from '../../components/Video'
import BannerSupporting from '../../sections/banners/BannerSupporting'
import BlogSection from '../../sections/kariera/BlogSection'

const Kariera = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/kariera.png/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      main1: file(absolutePath: { regex: "/images/company/career.png/" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
            aspectRatio: 1.77
          )
        }
      }
      gptw23pl: file(absolutePath: { regex: "/images/company/gptw23pl_large.png/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
      gptw23en: file(absolutePath: { regex: "/images/company/gptw23en_large.png/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
            formats: [PNG]
            aspectRatio: 1.77
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Dowiedz się dlaczego my',
      en: 'Learn why us',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button }}
        image={query.image.childImageSharp}
        position='top'
      />
      {lang !== 'en' && (
        <Main
          id={`oferty-pracy`}
          h={2}
          s={1}
          title={jobsMain.title[lang]}
          desc={jobsMain.desc[lang]}
          subtitle={jobsMain.subtitle[lang]}
        >
          <JobSection />
        </Main>
      )}
      <Main
        h={1}
        title={main.title[lang]}
        subtitle={main.subtitle[lang]}
        body1={main.body[lang]}
      >
        {main.gptw23?.map(({ title, desc }, id) => {
            const imageKey = `gptw23${lang}`;

            const image = query[imageKey].childImageSharp;

            return (
                <ImageSection
                    h={2}
                    key={id}
                    title={title[lang]}
                    desc={desc[lang]}
                    image={image}
                />
            );
        })}
      </Main>
      <Main h={2} title={features.title[lang]} desc={features.desc[lang]}>
        <Features data={features.features} gridRepeat={3} />
      </Main>
      <Main h={2} title={video.title[lang]} body={video.desc[lang]}>
        <div css={{ paddingTop: '4rem' }} />
        <Video video={video.video} videos={video.videos} />

        {/* {main.sections?.map(({ title, desc }, id) => (
          <ImageSection
            h={2}
            key={id}
            title={title[lang]}
            desc={desc[lang]}
            image={query[`main${id + 1}`].childImageSharp}
          />
        ))} */}
      </Main>
      <Main h={2} title={supporting.title[lang]}>
        <Video video={supporting.video} videos={supporting.videos} />
      </Main>
      <BannerSupporting />
      {/* <Main h={2} title={offices.title[lang]} desc={offices.desc[lang]}>
        <Video video={offices.video} />
      </Main> */}
      {/* <Main h={2} title={video.title[lang]}>
        <Video video={video.video} />
      </Main> */}
      <BlogSection skip={3} />
      <Main
        h={2}
        s={1}
        title={join.title[lang]}
        desc={join.desc[lang]}
        subtitle={join.subtitle[lang]}
      >
        <Collapses data={join.stages} openAll />
        <BannerRecruitment />
      </Main>
    </Layout>
  )
}

const sTest = {
  gridTemplateColumns: '1fr',
}

export default Kariera

export const seo = {
  url: 'kariera/praca',
  title: {
    pl: 'Oferty Pracy',
    en: 'Job Offers',
  },
  desc: {
    pl: `Aktualna lista naszych otwartych rekrutacji. Aplikuj przez formularz lub bezpośrednio na mail.`,
    en: `The current list of our open recruitments. Apply via the form or directly by e-mail!`,
  },
  keywords: [
    'omida',
    'sea and air',
    'sea & air',
    'spedytor',
    'oferty pracy',
    'kariera w tsl',
  ],
}

export const intro = {
  title: {
    pl: 'Oferty Pracy',
    en: 'Job Offers',
  },
  desc: {
    pl: `Aktualna lista naszych otwartych rekrutacji. Aplikuj przez formularz lub bezpośrednio na mail.`,
    en: `The current list of our open recruitments. Apply via the form or directly by e-mail!`,
  },
}

export const main = {
  title: {
    pl: 'Aktualne oferty pracy',
    en: 'On-going job offers',
  },
  subtitle: {
    pl: 'Sea & Air Kariera',
    en: 'Sea & Air Career',
  },
  desc: {
    pl: 'Jesteś zainteresowany pracą w Omida? Zapraszamy Cię do wzięcia udziału w otwartych rekrutacjach. Jeśli nie znalazłeś oferty dla siebie, ale Twoja kariera jest związana z transportem - napisz do nas za pomocą formularza!',
    en: 'Are you interested in working for Omida? We invite you to take part in open recruitments. If you have not found an offer for yourself, but your career is related to transport - write to us using the form!',
  },
}

export const video = {
  title: {
    pl: 'Zobacz nasze biuro',
    en: 'See our office',
  },
  video: 'https://www.youtube.com/embed/aJRXUBxyN_c',
}

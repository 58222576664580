import React from 'react'
import { sBlogs } from './BlogSection'
import BlogTile from '../../components/BlogTile'
// import { useLangContext } from '../../context/lang.context'
import { graphql, useStaticQuery } from 'gatsby'

const JobSection = () => {
  // const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/praca.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
      jobs: allContentfulJob {
        nodes {
          slug
          lang {
            slug
          }
          city
          title
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 100
              placeholder: DOMINANT_COLOR
              formats: [JPG]
              aspectRatio: 1.77
            )
          }
        }
      }
    }
  `)

  const jobs = query.jobs.nodes
    .filter((node) => node.lang.slug === 'pl')
    .map(({ title, slug, image, city }) => ({
      title,
      slug,
      image,
      desc: city,
    }))

  return (
    <div css={sBlogs}>
      {jobs.map((job, id) => (
        <BlogTile key={id} data={job} prefix={'/kariera/'} />
      ))}
    </div>
  )
}

export default JobSection
